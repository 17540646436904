<template>
    <div id="project-approval-form" class="flex stacked-form-questions qc-checkbox-in-form">
        <vue-form
            v-if="projectReviewData && projectApprovalFormConfiguration"
            :form-config="projectApprovalFormConfiguration"
            :form-data="projectReviewData"
            :pass-thru="true"
            :auto-save="true"
            :is-saving="saving"
            @changed="checkForChecklistUpdates"
        ></vue-form>
    </div>
</template>
<script>
    import { mapActions } from 'vuex';
    import { debounce } from 'lodash';
    import axios from 'axios';
    import { assignOnObject, byString } from "laravel-vue-forms";
    export default {

        props: {
            projectApprovalFormConfiguration: {
                required: true,
                type: Object
            },
            project: {
                required: true,
                type: Object
            },
            projectReview: {
                required: true,
                type: Object
            },
            checklistType: {
                required: false,
                type: String,
                default: 'pre_approval_review'
            }
        },

        data() {
            return {
                projectReviewData: null,
                saving: false,
            }
        },

        created() {
            this.syncFormData();
        },

        computed: {
            projectProgramTrack() {
                return this.$store.getters.getProgramTrackForId(this.project.program_track_id);
            },
            projectProgramYear() {
                return this.$store.getters.getProgramYearForId(this.project.program_year_id);
            },
            checklistConfiguration() {

                if(!this.projectProgramTrack || !this.projectProgramYear) {
                    return null;
                }

                return this.$store.getters['checklists/getChecklistConfigurationForType'](
                    this.projectProgramTrack,
                    this.projectProgramYear,
                    this.checklistType
                )
            },
            currentChecklistItems() {
                return this.$store.getters['checklists/checklistItemsForModel'](this.projectReview, 'project_review');
            },
        },

        watch: {
            checklistConfiguration(newChecklistConfig) {
                this.syncFormData();
            },
            currentChecklistItems(updatedChecklistItems) {
                this.syncFormData();
            }
        },

        methods: {
            ...mapActions('qc_progress', [
                'setQCProgress'
            ]),
            syncFormData() {

                // if(!this.checklistConfiguration) {
                //     return null;
                // }

                var projectReviewData;
                if(!this.projectReviewData) {
                    projectReviewData = this.projectReview
                    projectReviewData.project = {
                        id: this.project.id
                    };
                } else {
                    projectReviewData = JSON.parse(JSON.stringify(this.projectReviewData));
                }

                this.projectApprovalFormConfiguration.fields.forEach(formField => {

                    if (formField.value_field.includes('project.')) { // append project fields onto data to add to form
                        let projectValueField = formField.value_field.split('.')[1];
                        projectReviewData.project[projectValueField] = this.project[projectValueField];
                    }

                    let checklistConfigItem = this.checklistConfiguration?.checklist_configuration_items.find(checklistConfigurationItem => {
                        return checklistConfigurationItem.name === formField.value_field;
                    });

                    if(!checklistConfigItem) { return; }

                    let currentChecklistItem = this.currentChecklistItems.find(item => {
                        return item.checklist_configuration_item_id === checklistConfigItem.id
                    });

                    if(currentChecklistItem && Boolean(currentChecklistItem.checked)) {
                        projectReviewData[formField.value_field] = true;
                    } else {
                        projectReviewData[formField.value_field] = false;
                    }
                }, this);

                this.projectReviewData = projectReviewData;

            },
            checkForChecklistUpdates(changedInfo) {

                var checklistItemValues = [];
                // pull out checklist items from the form

                if(!this.checklistConfiguration) {
                    return;
                }


                this.projectApprovalFormConfiguration.fields.forEach(formField => {
                    let checklistConfigItem = this.checklistConfiguration.checklist_configuration_items.find(checklistConfigurationItem => {
                        return checklistConfigurationItem.name === formField.value_field;
                    });
                    if (checklistConfigItem) {
                        return;
                    }

                    assignOnObject(this.projectReviewData, formField.value_field, byString(changedInfo, formField.value_field))

                    // if (formField.value_field.includes('project.')) { // append project fields onto data to add to form
                    //     let projectValueField = formField.value_field.split('.')[1];
                    //     this.projectReviewData.project[projectValueField] = changedInfo.project[projectValueField];
                    // } else {
                    //
                    // }
                });

                this.checklistConfiguration.checklist_configuration_items.forEach(checklistConfigItem => {
                    if (changedInfo[checklistConfigItem.name]) {
                        checklistItemValues.push({
                            checklistConfigItem,
                            checked: true
                        });
                    } else if (changedInfo.hasOwnProperty(checklistConfigItem.name)) {
                        checklistItemValues.push({
                            checklistConfigItem,
                            checked: false
                        });
                    }
                });

                this.saveProjectReviewData();

                // next pull only the checklist items that actually changed
                var changedChecklistItems = [];
                checklistItemValues.forEach(checklistItemValue => {
                    let currentChecklistItem = this.currentChecklistItems.find(item => {
                        return item.checklist_configuration_item_id === checklistItemValue.checklistConfigItem.id
                    });

                    if (currentChecklistItem && Boolean(currentChecklistItem.checked) !== Boolean(checklistItemValue.checked)) {
                        changedChecklistItems.push(checklistItemValue);
                    } else if (checklistItemValue.checked && !currentChecklistItem) {
                        changedChecklistItems.push(checklistItemValue);
                    }
                });

                // update the changed checklist items (generally this will really only ever be 1 in practice)
                changedChecklistItems.forEach(changedChecklistItem => {
                    this.setQCProgress({
                        type: changedChecklistItem.checklistConfigItem.name,
                        progress: changedChecklistItem.checked
                    });
                });

                if (window.Bus && changedChecklistItems.length > 0) {
                    window.Bus.$emit('updateChecklist');
                }
            },
            saveProjectReviewData: debounce(function() {
                this.saving = true;
                axios.patch('/api/projects/' + this.project.id + '/reviews/' + this.projectReview.id, {
                    entityId: this.projectReview.id,
                    formConfigurationId:this.projectApprovalFormConfiguration.id,
                    data: this.projectReviewData
                }).then(response => {
                    this.saving = false;
                }).catch(error => {
                    window.notify.apiError(error);
                    this.saving = false;
                })

            }, 2500)
        }

    }

</script>
