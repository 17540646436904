<template>
    <div class="form-group"
         :id="fieldName + '-select-field'"
         :class="{ 'has-error': form.errors.has(this.fieldConfig.value_field) }">
        <label class="form-control-label" v-if="showLabel">
            <span v-html="fieldConfig.label"></span>
            <span class="required" v-if="fieldConfig.field_extra.required">&nbsp;&nbsp;(*)</span>
            <span v-if="withHelpIcon" :class="fieldConfig.field_extra.withIcon" :title="fieldConfig.field_extra.helpText"></span>
        </label>

        <div class="flex items-center w-full">
            <multi-select
                :value="optionValue"
                :options="fieldConfig.options"
                :track-by="fieldConfig.optionValueField"
                :label="fieldConfig.optionLabelField"
                :placeholder="fieldConfig.label"
                @input="updateValue"
                :disabled="fieldConfig.disabled === 1"
                :show-labels="showMultiselectLabels"
                :select-label="selectLabel"
                :deselect-label="deselectLabel"
                :allow-empty="allowEmpty"
                class="form-control h-auto"
            ></multi-select>
            <span :class="'text-red fa ' + this.fieldConfig.flag.icon" v-if="shouldFlag"></span>
        </div>
        <span class="help-block" v-if="form.errors.has(this.fieldConfig.value_field)">
            {{ form.errors.get(this.fieldConfig.value_field, true) }}
        </span>
        <div v-if="hasHelpText">
            <span v-html="fieldConfig.field_extra.helpText"></span>
        </div>

    </div>
</template>
<script>
    import MultiSelect from 'vue-multiselect';
    import { FormFieldMixin, HasOptionsMixin } from 'laravel-vue-forms';

    import axios from 'axios';
    export default {

        components: {
            MultiSelect
        },

        props: {
            showMultiselectLabels: {
                type: Boolean,
                default: true,
            },
            allowEmpty: {
                type: Boolean,
                default: true,
            },
            selectLabel: {
                type: String,
                default: 'Press enter to select',
            },
            deselectLabel: {
                type: String,
                default: 'Press enter to remove'
            },
            flag: {
                type: Object,
                default: null,
            }
        },

        mixins: [
            FormFieldMixin,
            HasOptionsMixin
        ],


        created() {

            if(this.form && this.form.formConfig && Array.isArray(this.form.formConfig.fields)) {
                this.form.formConfig.fields.forEach(field => {
                    if(field.name === this.fieldName) {

                        var fieldExtra = this.getFormFieldFieldExtra(field);
                        this.$set(this.fieldConfig, 'flag', null);
                        if(fieldExtra.flag) {
                            this.$set(this.fieldConfig, 'flag', fieldExtra.flag);
                        }
                    }
                });

            }else {
                this.$set(this.fieldConfig, 'flag', this.flag);
            }
        },


        computed: {
            shouldFlag() {

                if(!this.fieldConfig.flag || !this.fieldConfig.options || !this.optionValue) {
                    return false;
                }

                if(this.optionValue && this.optionValue[this.fieldConfig.optionLabelField] === this.fieldConfig.flag.value) {
                    return true;
                }

                return false;
            }
        },

        methods: {
            updateValue(value) {
                this.form.errors.clear(this.fieldConfig.value_field);
                if(value) {
                    this.$emit('input', value[this.fieldConfig.optionValueField]);
                } else {
                    this.$emit('input', null)
                }
            },
        }

    }
</script>
